import './App.css';
import { Pane } from 'evergreen-ui';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { useEffect, useState, Suspense, lazy } from 'react';

const Home = lazy(() => import('./Pages/Home'));

const LoadingElement = () => (
  <div className="loaderMain">
      <div className="loader">
          <div className="inner"></div>
      </div>
  </div>
)

function App() {
  
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  useEffect(() => {
    if (isFirstLoading) {
      setIsFirstLoading(false);
    }
  }, [isFirstLoading]);

  return (
    <Router>
      <Pane>
        <Routes>
          <Route path="/27.9881°N86.9250°E" element={
            <Suspense fallback={<LoadingElement />}>
              <Home />
            </Suspense>
          } /> 
          <Route  path="/" element={<Navigate to="/27.9881%C2%B0N86.9250%C2%B0E" replace />} /> 
        </Routes>
      </Pane>
    </Router>
  );
}

export default App;