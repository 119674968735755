import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const LoadingElement = () => (
    <div className="loaderMain">
        <div class="wrap">
            <div class="loading">
                <div class="bounceball"></div>
                <div class="text">CONNECTING TO EVEREST..</div>
            </div>
        </div>
    </div>
)

function preloadImages(imageUrls) {
    return new Promise((resolve, reject) => {
        let loaded = 0;
        const total = imageUrls.length;
        const images = [];

        const onLoad = () => {
            loaded++;
            if (loaded === total) {
                // console.log(total);
                setTimeout(resolve(images), 1000);
                
            }
        };

        imageUrls.forEach(url => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                console.log(url);
                onLoad();
            }
            img.onerror = () => {
                console.error(`Failed to load image: ${url}`);
                // onLoad();
            }
            images.push(img);
        });
    });
}

// function preloadFonts(fontUrls) {
//     return Promise.all(fontUrls.map(url => new Promise((resolve, reject) => {
//         const style = document.createElement('style');
//         style.textContent = `@font-face { font-family: "FontName"; src: url(${url}) format('woff2'); }`;
//         document.head.appendChild(style);
//         style.onload = resolve;
//         style.onerror = reject;
//     })));
// }

function preloadAssets() {
    return Promise.all([
        preloadImages([
            '/designText.svg', 
            '/Group 35.svg', 
            '/Group 38.svg',
            '/Group 175 2.svg',
            '/Group 178.svg',
            '/Group 179.svg',
            '/Group 182.svg',
            '/Group 191.svg',
            '/jomo.png',
            '/JOMO.svg',
            '/jomoMenu.svg',
            '/jomoTextBlack.svg',
            '/logo.svg',
            '/Vector (4).svg',
            '/whereisjomo.svg',
            '/workedWith.svg',
        ]),
        // preloadFonts([
        //     './Assets/fonts/Poppins-Black.ttf',
        //     './Assets/fonts/Poppins-BlackItalic.ttf',
        //     './Assets/fonts/Poppins-Bold.ttf',
        //     './Assets/fonts/Poppins-BoldItalic.ttf',
        //     './Assets/fonts/Poppins-ExtraBold.ttf',
        //     './Assets/fonts/Poppins-ExtraBoldItalic.ttf',
        //     './Assets/fonts/Poppins-ExtraLight.ttf',
        //     './Assets/fonts/Poppins-ExtraLightItalic.ttf',
        //     './Assets/fonts/Poppins-Italic.ttf',
        //     './Assets/fonts/Poppins-Light.ttf',
        //     './Assets/fonts/Poppins-LightItalic.ttf',
        //     './Assets/fonts/Poppins-Medium.ttf',
        //     './Assets/fonts/Poppins-MediumItalic.ttf',
        //     './Assets/fonts/Poppins-SemiBold.ttf',
        //     './Assets/fonts/Poppins-SemiBoldItalic.ttf',
        //     './Assets/fonts/Poppins-Thin.ttf',
        //     './Assets/fonts/Poppins-ThinItalic.ttf',
        //     './Assets/fonts/Yomogi-Regular.ttf'
        // ])
    ]);
}

function Preloader({ children }) {
    const [preloading, setPreloading] = useState(true);

    useEffect(() => {
        preloadAssets().then(() => setPreloading(false));
    }, []);

    return preloading ? <LoadingElement /> : children;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Preloader>
        <App />
    </Preloader>
);
